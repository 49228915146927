import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';

import { ParameterService, Parameters, ReportType } from 'kazi-reports-prod'
import { environment } from '../../../../environments/environment';
import { Subscription, Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { UserSettingsService } from '../../../core/services/user-settings.service';
import { skipLast, filter, switchMap, finalize } from 'rxjs/operators';
import { IdentifyingClient, JobScanDto, TalentScanDto } from '../../api/identifying-api.generated';
import { AdministratorClient, ClientDto } from '../../api/administrator-api.generated';

@Component({
    selector: 'kazi-report',
    templateUrl: './report.component.html',
    styleUrls: ['./report.component.scss'],
})
export class ReportComponent implements OnInit, OnDestroy {
    loaded: boolean;
    error: boolean;
    print: boolean;
    reportType: ReportType;
    currentClient: ClientDto;

    subscriptions: Subscription[];

    constructor(
        private parameterService: ParameterService,
        private route: ActivatedRoute,
        private translate: TranslateService,
        private userSettingsService: UserSettingsService,
        private router: Router,
        private identifyingClient: IdentifyingClient,
        private administratorClient: AdministratorClient
    ) {
        this.subscriptions = [];

        this.parameterService.parameters = {
            identifyingApiUrl: environment.api.identifyingUrl,
            taggingApiUrl: environment.api.taggingUrl,
            language: this.translate.currentLang,
            theme: 'kazi', // default theme
            themeCDN: 'https://cdn.kazi.be',
        } as Parameters;
    }

    get talentScanReport() {
        return this.reportType === ReportType.TalentScan;
    }

    get jobScanReport() {
        return this.reportType === ReportType.JobScan;
    }

    get reflectionScanReport() {
        return this.reportType === ReportType.ReflectionScan;
    }

    get isCompareReport() {
        return this.isCompareTalentWithJob || this.reportType == ReportType.CompareJobWithTalent || this.reportType == ReportType.CompareJobWithJob;
    }

    get isEngage() {
        if (this.currentClient)
            return this.currentClient.name.includes('engage');
        else
            return false;
    }

    get isCompareTalentWithJob() {
        return this.reportType == ReportType.CompareTalentWithJob;
    }

    get isCompareJobWithTalent() {
        return this.reportType == ReportType.CompareJobWithTalent && this.route.snapshot.url.filter((segment) => segment.path === 'jobs').length > 0;
    }

    get isCompareReflectionWithTalent() {
        return this.reportType == ReportType.CompareJobWithTalent && this.route.snapshot.url.filter((segment) => segment.path === 'reflections').length > 0;
    }

    ngOnInit() {
        //this.translate.use(this.route.snapshot.params.lang);
        this.loaded = false;
        this.error = false;
        this.print = this.route.snapshot.url.filter((segment) => segment.path === 'print').length > 0;

        if (this.print) {
            document.body.classList.add('body-overflow');
        }

        const id = this.route.snapshot.params.id;
        this.reportType =
            this.route.snapshot.url.filter((segment) => segment.path === 'talents').length > 0 ? ReportType.TalentScan : ReportType.JobScan;

        if (this.reportType != ReportType.TalentScan) {
            this.reportType =
                this.route.snapshot.url.filter((segment) => segment.path === 'reflections').length > 0 ? ReportType.ReflectionScan : ReportType.JobScan;
        }

        var isCompare = this.route.snapshot.url.some((segment) => segment.path === 'compare');
        if (isCompare) {
            this.reportType = (this.reportType == ReportType.JobScan || this.reportType == ReportType.ReflectionScan) ? ReportType.CompareJobWithTalent : ReportType.CompareTalentWithJob;
            this.parameterService.parameters.id2 = this.route.snapshot.params.id2;
        }

        isCompare = this.route.snapshot.url.some((segment) => segment.path === 'jobcompare');
        if (isCompare) {
            this.reportType = (this.reportType == ReportType.JobScan || this.reportType == ReportType.ReflectionScan) ? ReportType.CompareJobWithJob : ReportType.CompareJobWithTalent;
            this.parameterService.parameters.id2 = this.route.snapshot.params.id2;
        }

        this.parameterService.parameters.id = id;
        this.parameterService.parameters.reportType = this.reportType;
        this.setTheme(id);

        const currentAccountSub = this.userSettingsService.currentAccount$
            .pipe(
                skipLast(1),
                filter((p) => !!p)
            )
            .subscribe((account) => {
                this.router.navigateByUrl(this.talentScanReport ? 'talents' : 'jobs');
            });

        this.subscriptions.push(currentAccountSub);
    }

    private setTheme(id: string) {
        const dtoObervable: Observable<any> =
            this.reportType === ReportType.TalentScan || this.reportType == ReportType.CompareTalentWithJob
                ? this.identifyingClient.getTalentScan(id)
                : this.identifyingClient.getJobScan(id);

        const themeSub = dtoObervable
            .pipe(
                switchMap((item: TalentScanDto | JobScanDto) => this.administratorClient.getClientById(item.clientId)),
                finalize(() => (this.loaded = true)) // loaded=true shows the report
            )
            .subscribe((client: ClientDto) => {
                this.currentClient = client;
                this.parameterService.parameters.theme = client.theme.toLowerCase();
                if (this.isEngage) {
                    if (this.reportType == ReportType.TalentScan || this.reportType == ReportType.ReflectionScan) {
                        this.parameterService.parameters.contextId = 10;
                    } else {
                        this.parameterService.parameters.contextId = 11;
                    }
                }
            });

        this.subscriptions.push(themeSub);
    }

    onError($event) {
        this.error = true;
        const reportTypeName = this.reportType === 1 ? 'talentscan' : 'jobscan';
        throw new Error(`Error loading ${reportTypeName} spider report (${location.href}`);
    }

    showPrintDialog() {
        setTimeout(() => print(), 500);
    }

    openPublicLink() {

        const id = this.route.snapshot.params.id;
        var url = this.currentClient.ccReportUrl;
        const clientName = this.currentClient.name;

        if (this.reportType == ReportType.TalentScan) {
            url = url.replace('{0}', id + '/nl/' + this.translate.currentLang + '?header=false');

            window.open(url, '_blank');
        } else if (this.reportType == ReportType.JobScan || this.reportType == ReportType.ReflectionScan) {

            const dtoObervable: Observable<any> = this.identifyingClient.getJobScan(id);
            dtoObervable
                .subscribe((job: JobScanDto) => {
                    if (job.title.includes("(CP)")) {
                        url = url.replace('job', 'job-company');
                    } else {
                        if (!url.includes("job-individual")) {
                            url = url.replace('job', 'job-individual');
                        }
                    }

                    url = url.replace('{0}', id + '/nl/' + this.translate.currentLang + '?header=false');
                    window.open(url, '_blank');
                });
        }
        else if (this.reportType == ReportType.CompareJobWithTalent || this.reportType == ReportType.CompareTalentWithJob) {
            const id2 = this.route.snapshot.params.id2;
            url = url.replace('{0}', id + '/compare/' + id2);
            window.open(url, '_blank');
        } else if (this.reportType == ReportType.CompareJobWithJob) {
            const id2 = this.route.snapshot.params.id2;
            url = url.replace('{0}', id + '/jobcompare/' + id2);
            window.open(url, '_blank');
        }

    }

    openPublicDetailLink() {
        const id = this.route.snapshot.params.id;
        var url = this.currentClient.ccReportUrl;

        if (this.reportType == ReportType.CompareJobWithTalent) {
            const id2 = this.route.snapshot.params.id2;

            const dtoObervable: Observable<any> = this.identifyingClient.getJobScan(id);
            dtoObervable
                .subscribe((job: JobScanDto) => {
                    if (job.title.includes("(CP)")) {
                        url = url.replace('job', 'job-company');
                    } else {
                        if (!url.includes("job-individual")) {
                            url = url.replace('job', 'job-individual');
                        }
                    }

                    url = url.replace('{0}', id + '/compare/' + id2 + "/detail");
                    window.open(url, '_blank');
                });




        } else if (this.reportType == ReportType.CompareTalentWithJob) {
            const id2 = this.route.snapshot.params.id2;
            const dtoObervable: Observable<any> = this.identifyingClient.getJobScan(id2);
            dtoObervable
                .subscribe((job: JobScanDto) => {
                    if (job.title.includes("(CP)")) {
                        url = url.replace('career', 'job-company');
                    } else {
                        url = url.replace('career', 'job-individual');
                    }

                    url = url.replace('{0}', id2 + '/compare/' + id + "/detail");
                    window.open(url, '_blank');
                });

        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }

    compareTalentWithJob({ jobScanId, talentScanId }: { jobScanId: string; talentScanId: string }) {
        console.log(jobScanId, talentScanId);
        this.router.navigate(['talents', talentScanId, 'compare', jobScanId]);
    }

    compareJobWithTalent({ jobScanId, talentScanId }: { jobScanId: string; talentScanId: string }) {
        console.log(jobScanId, talentScanId);

        const dtoObervable: Observable<any> = this.identifyingClient.getJobScan(jobScanId);
        const dtoJobObervable: Observable<any> = this.identifyingClient.getJobScan(talentScanId);
        dtoObervable
            .subscribe((job: JobScanDto) => {

                dtoJobObervable
                    .pipe(
                        catchError((error) => {
                            console.error('Caught error:', error);
                            // Return a fallback value or empty observable
                            if (job.title.includes("(IP)")) {
                                this.router.navigate(['reflections', jobScanId, 'compare', talentScanId]);
                            } else {
                                this.router.navigate(['jobs', jobScanId, 'compare', talentScanId]);
                            }
                            return of();
                        })
                    )
                    .subscribe((job2: JobScanDto) => {
                        if (job.title.includes("(IP)")) {
                            this.router.navigate(['reflections', jobScanId, 'jobcompare', talentScanId]);
                        } else {
                            this.router.navigate(['jobs', jobScanId, 'jobcompare', talentScanId]);
                        }
                    });
            });
    }
}
