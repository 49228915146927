import { OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { ParameterService, ReportType } from 'kazi-reports-prod';
import { environment } from '../../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { UserSettingsService } from '../../../core/services/user-settings.service';
import { skipLast, filter, switchMap, finalize } from 'rxjs/operators';
import { IdentifyingClient } from '../../api/identifying-api.generated';
import { AdministratorClient } from '../../api/administrator-api.generated';
var ReportComponent = /** @class */ (function () {
    function ReportComponent(parameterService, route, translate, userSettingsService, router, identifyingClient, administratorClient) {
        this.parameterService = parameterService;
        this.route = route;
        this.translate = translate;
        this.userSettingsService = userSettingsService;
        this.router = router;
        this.identifyingClient = identifyingClient;
        this.administratorClient = administratorClient;
        this.subscriptions = [];
        this.parameterService.parameters = {
            identifyingApiUrl: environment.api.identifyingUrl,
            taggingApiUrl: environment.api.taggingUrl,
            language: this.translate.currentLang,
            theme: 'kazi',
            themeCDN: 'https://cdn.kazi.be',
        };
    }
    Object.defineProperty(ReportComponent.prototype, "talentScanReport", {
        get: function () {
            return this.reportType === ReportType.TalentScan;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReportComponent.prototype, "jobScanReport", {
        get: function () {
            return this.reportType === ReportType.JobScan;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReportComponent.prototype, "reflectionScanReport", {
        get: function () {
            return this.reportType === ReportType.ReflectionScan;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReportComponent.prototype, "isCompareReport", {
        get: function () {
            return this.isCompareTalentWithJob || this.reportType == ReportType.CompareJobWithTalent || this.reportType == ReportType.CompareJobWithJob;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReportComponent.prototype, "isEngage", {
        get: function () {
            if (this.currentClient)
                return this.currentClient.name.includes('engage');
            else
                return false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReportComponent.prototype, "isCompareTalentWithJob", {
        get: function () {
            return this.reportType == ReportType.CompareTalentWithJob;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReportComponent.prototype, "isCompareJobWithTalent", {
        get: function () {
            return this.reportType == ReportType.CompareJobWithTalent && this.route.snapshot.url.filter(function (segment) { return segment.path === 'jobs'; }).length > 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReportComponent.prototype, "isCompareReflectionWithTalent", {
        get: function () {
            return this.reportType == ReportType.CompareJobWithTalent && this.route.snapshot.url.filter(function (segment) { return segment.path === 'reflections'; }).length > 0;
        },
        enumerable: true,
        configurable: true
    });
    ReportComponent.prototype.ngOnInit = function () {
        var _this = this;
        //this.translate.use(this.route.snapshot.params.lang);
        this.loaded = false;
        this.error = false;
        this.print = this.route.snapshot.url.filter(function (segment) { return segment.path === 'print'; }).length > 0;
        if (this.print) {
            document.body.classList.add('body-overflow');
        }
        var id = this.route.snapshot.params.id;
        this.reportType =
            this.route.snapshot.url.filter(function (segment) { return segment.path === 'talents'; }).length > 0 ? ReportType.TalentScan : ReportType.JobScan;
        if (this.reportType != ReportType.TalentScan) {
            this.reportType =
                this.route.snapshot.url.filter(function (segment) { return segment.path === 'reflections'; }).length > 0 ? ReportType.ReflectionScan : ReportType.JobScan;
        }
        var isCompare = this.route.snapshot.url.some(function (segment) { return segment.path === 'compare'; });
        if (isCompare) {
            this.reportType = (this.reportType == ReportType.JobScan || this.reportType == ReportType.ReflectionScan) ? ReportType.CompareJobWithTalent : ReportType.CompareTalentWithJob;
            this.parameterService.parameters.id2 = this.route.snapshot.params.id2;
        }
        isCompare = this.route.snapshot.url.some(function (segment) { return segment.path === 'jobcompare'; });
        if (isCompare) {
            this.reportType = (this.reportType == ReportType.JobScan || this.reportType == ReportType.ReflectionScan) ? ReportType.CompareJobWithJob : ReportType.CompareJobWithTalent;
            this.parameterService.parameters.id2 = this.route.snapshot.params.id2;
        }
        this.parameterService.parameters.id = id;
        this.parameterService.parameters.reportType = this.reportType;
        this.setTheme(id);
        var currentAccountSub = this.userSettingsService.currentAccount$
            .pipe(skipLast(1), filter(function (p) { return !!p; }))
            .subscribe(function (account) {
            _this.router.navigateByUrl(_this.talentScanReport ? 'talents' : 'jobs');
        });
        this.subscriptions.push(currentAccountSub);
    };
    ReportComponent.prototype.setTheme = function (id) {
        var _this = this;
        var dtoObervable = this.reportType === ReportType.TalentScan || this.reportType == ReportType.CompareTalentWithJob
            ? this.identifyingClient.getTalentScan(id)
            : this.identifyingClient.getJobScan(id);
        var themeSub = dtoObervable
            .pipe(switchMap(function (item) { return _this.administratorClient.getClientById(item.clientId); }), finalize(function () { return (_this.loaded = true); }) // loaded=true shows the report
        )
            .subscribe(function (client) {
            _this.currentClient = client;
            _this.parameterService.parameters.theme = client.theme.toLowerCase();
            if (_this.isEngage) {
                if (_this.reportType == ReportType.TalentScan || _this.reportType == ReportType.ReflectionScan) {
                    _this.parameterService.parameters.contextId = 10;
                }
                else {
                    _this.parameterService.parameters.contextId = 11;
                }
            }
        });
        this.subscriptions.push(themeSub);
    };
    ReportComponent.prototype.onError = function ($event) {
        this.error = true;
        var reportTypeName = this.reportType === 1 ? 'talentscan' : 'jobscan';
        throw new Error("Error loading " + reportTypeName + " spider report (" + location.href);
    };
    ReportComponent.prototype.showPrintDialog = function () {
        setTimeout(function () { return print(); }, 500);
    };
    ReportComponent.prototype.openPublicLink = function () {
        var _this = this;
        var id = this.route.snapshot.params.id;
        var url = this.currentClient.ccReportUrl;
        var clientName = this.currentClient.name;
        if (this.reportType == ReportType.TalentScan) {
            url = url.replace('{0}', id + '/nl/' + this.translate.currentLang + '?header=false');
            window.open(url, '_blank');
        }
        else if (this.reportType == ReportType.JobScan || this.reportType == ReportType.ReflectionScan) {
            var dtoObervable = this.identifyingClient.getJobScan(id);
            dtoObervable
                .subscribe(function (job) {
                if (job.title.includes("(CP)")) {
                    url = url.replace('job', 'job-company');
                }
                else {
                    if (!url.includes("job-individual")) {
                        url = url.replace('job', 'job-individual');
                    }
                }
                url = url.replace('{0}', id + '/nl/' + _this.translate.currentLang + '?header=false');
                window.open(url, '_blank');
            });
        }
        else if (this.reportType == ReportType.CompareJobWithTalent || this.reportType == ReportType.CompareTalentWithJob) {
            var id2 = this.route.snapshot.params.id2;
            url = url.replace('{0}', id + '/compare/' + id2);
            window.open(url, '_blank');
        }
        else if (this.reportType == ReportType.CompareJobWithJob) {
            var id2 = this.route.snapshot.params.id2;
            url = url.replace('{0}', id + '/jobcompare/' + id2);
            window.open(url, '_blank');
        }
    };
    ReportComponent.prototype.openPublicDetailLink = function () {
        var id = this.route.snapshot.params.id;
        var url = this.currentClient.ccReportUrl;
        if (this.reportType == ReportType.CompareJobWithTalent) {
            var id2_1 = this.route.snapshot.params.id2;
            var dtoObervable = this.identifyingClient.getJobScan(id);
            dtoObervable
                .subscribe(function (job) {
                if (job.title.includes("(CP)")) {
                    url = url.replace('job', 'job-company');
                }
                else {
                    if (!url.includes("job-individual")) {
                        url = url.replace('job', 'job-individual');
                    }
                }
                url = url.replace('{0}', id + '/compare/' + id2_1 + "/detail");
                window.open(url, '_blank');
            });
        }
        else if (this.reportType == ReportType.CompareTalentWithJob) {
            var id2_2 = this.route.snapshot.params.id2;
            var dtoObervable = this.identifyingClient.getJobScan(id2_2);
            dtoObervable
                .subscribe(function (job) {
                if (job.title.includes("(CP)")) {
                    url = url.replace('career', 'job-company');
                }
                else {
                    url = url.replace('career', 'job-individual');
                }
                url = url.replace('{0}', id2_2 + '/compare/' + id + "/detail");
                window.open(url, '_blank');
            });
        }
    };
    ReportComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (sub) { return sub.unsubscribe(); });
    };
    ReportComponent.prototype.compareTalentWithJob = function (_a) {
        var jobScanId = _a.jobScanId, talentScanId = _a.talentScanId;
        console.log(jobScanId, talentScanId);
        this.router.navigate(['talents', talentScanId, 'compare', jobScanId]);
    };
    ReportComponent.prototype.compareJobWithTalent = function (_a) {
        var _this = this;
        var jobScanId = _a.jobScanId, talentScanId = _a.talentScanId;
        console.log(jobScanId, talentScanId);
        var dtoObervable = this.identifyingClient.getJobScan(jobScanId);
        var dtoJobObervable = this.identifyingClient.getJobScan(talentScanId);
        dtoObervable
            .subscribe(function (job) {
            dtoJobObervable
                .pipe(catchError(function (error) {
                console.error('Caught error:', error);
                // Return a fallback value or empty observable
                if (job.title.includes("(IP)")) {
                    _this.router.navigate(['reflections', jobScanId, 'compare', talentScanId]);
                }
                else {
                    _this.router.navigate(['jobs', jobScanId, 'compare', talentScanId]);
                }
                return of();
            }))
                .subscribe(function (job2) {
                if (job.title.includes("(IP)")) {
                    _this.router.navigate(['reflections', jobScanId, 'jobcompare', talentScanId]);
                }
                else {
                    _this.router.navigate(['jobs', jobScanId, 'jobcompare', talentScanId]);
                }
            });
        });
    };
    return ReportComponent;
}());
export { ReportComponent };
